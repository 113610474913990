<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import {
  shopServiceMethods,
  notificationMethods
} from "@/state/helpers";

import {
  required, 
} from "vuelidate/lib/validators";

import { advertisementService } from '../../../services/advertisement.service';

const initForm = { title: "", description: "", icon: "",callToActionURL: "", createdBy: JSON.parse(localStorage.getItem('user')).id,};

export default {
  page: {
    title: "Manage Advertisements ",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      tableData: [],
      title: "Manage Advertisements",
      items: [
        {
          text: "Smart Exchange",
          href: "/"
        },
        {
          text: "Shops",
          active: true
        }
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 25,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: false,
      fields: [
        { key: "id", sortable: true },
        { key: "title", sortable: true },
        { key: "description", sortable: true },
        { key: "icon", sortable: true },
        { key: "callToActionURL", title:"When Customer Clicks", sortable: true },
        { key: "actions"}
      ],

      form: {
        title: "",
        description: "",
        icon: null,
        callToActionURL: "",
        createdBy: JSON.parse(localStorage.getItem('user')).id,
      },

      editForm: {
        title: "",
        description: "",
        icon: null,
        callToActionURL: "",
        createdBy: JSON.parse(localStorage.getItem('user')).id,
      },

      submitted: false,
      submitform: false,
      submit: false,
      showform: false
    };
  },

  validations: {
    form: {
      title: { required },
      description: { required },
      callToActionURL: { required }
    },
  },

  created() {
    this.loadAllAdvertisements()
  },

  computed: {
    rows() {
      return this.tableData.length;
    },
    notification() {
        return this.$store ? this.$store.state.notification : null;
      }
  },
  mounted() {
    this.totalRows = this.items.length;
  },

  methods: { 
    ...shopServiceMethods,
    ...notificationMethods,

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    formSubmit() {

      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
         this.submitted = false;
        return;
      } else {
          const {form} = this;
          const advertisementForm = {
                "operation" : "advertisementCreation",
                "accessToken" : JSON.parse(localStorage.getItem('access_token')),
                "requestBody" : form
          }; 
          advertisementService.addAdvertisement(advertisementForm).then(result=>{
            if(result.status=="SUCCESS"){
              this.loadAllAdvertisements()
              this.notificationType = "success";
              this.noticationIcon = "mdi-check-all";
              this.submitted = false;
              this.form = Object.assign({}, initForm);
            } else {
               this.submitted = false;
              this.notificationType = "danger";
              this.noticationIcon = "mdi-block-helper";
            }
          });
      }
    },

    storeState(shop){
      console.log(shop);
      // to be fix
      // alert(shop.name);
    },

    editShop() {
      this.submitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
         this.submitted = false;
        return;
      } else {
          const {editForm} = this;
          const shopForm = {
                "operation" : "shopCreation",
                "accessToken" : JSON.parse(localStorage.getItem('access_token')),
                "requestBody" : editForm
          };
          
          advertisementService.registerShop(shopForm).then(result=>{
            if(result.status=="SUCCESS"){
              this.loadAllShops()
              this.notificationType = "success";
              this.noticationIcon = "mdi-check-all";
              this.submitted = false;
              this.editForm = Object.assign({}, initForm);
            } else { 
              this.submitted = false;
              this.notificationType = "danger";
              this.noticationIcon = "mdi-block-helper";
            }
          });
      }
    },

    async loadAllAdvertisements() {
        try {
        await advertisementService.getAllAdvertisements().then(response=>{
            if(response.length>0){
                this.tableData = response;
              }
          });
        } catch (error) {
          console.log(error);
        }
    },
  },

};

</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <div class="row">

       <b-modal id="modal-standard" title="Edit Shop" title-class="font-18" hide-footer>
                      <div class="card-body">
                        <form class="needs-registration" @submit.prevent="editShop">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="form-group">
                                <label for="validationCustom01">Shop Name</label>
                                <input
                                  id="validationCustom01"
                                  v-model="editForm.name"
                                  type="text"
                                  class="form-control"
                                  placeholder="Shop Name"
                                />
                              </div>
                            </div>
                            </div>
                            <div class="row">
                            <div class="col-md-6">
                              <div class="form-group">
                                <label for="validationCustom02">Shop Phone Number</label>
                                <div>
                                  <input
                                    id="validationCustom02"
                                    v-model="editForm.phonenumber"
                                    type="number"
                                    class="form-control"
                                    placeholder="Enter phone number"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group">
                                <label for="validationCustom02">Shop Email address</label>
                                <div>
                                  <input
                                    id="validationCustom03"
                                    v-model="editForm.email"
                                    type="email"
                                    class="form-control"
                                    placeholder="Enter address "
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-md-12">
                              <div class="form-group">
                                <label for="validationCustom04">Physical Address</label>
                                <input
                                  id="validationCustom03"
                                  v-model="editForm.location"
                                  type="text"
                                  class="form-control"
                                  placeholder="Physical Address"
                                />
                              </div>
                            </div>
                          </div>
                          <button class="btn btn-primary" type="submit">Save changes</button>
                        </form>
                      </div>
                  </b-modal>
     <div class="col-xl-5">
        <b-alert
          :variant="notificationType" 
          class="mt-3"
          v-if="notification.message"
          show
          dismissible
        >
          <i :class="'mdi '+ noticationIcon + ' mr-2'"></i>{{notification.message}}
        </b-alert>
          <a href="javascript:void(0);" class="btn btn-primary mb-2" @click="showform = true" v-if="!showform">
            <i class="mdi mdi-plus mr-2"></i> Add Advertisement
          </a>

        <div class="card" v-if="showform">
          <div class="card-body">
            <form class="needs-registration" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="validationCustom01"> Title</label>
                    <input
                      id="validationCustom01"
                      v-model="form.title"
                      type="text"
                      class="form-control"
                      placeholder="Advertisement Title"
                      :class="{ 'is-invalid': submitted && $v.form.title.$error }"
                    />
                    <div v-if="submitted && $v.form.title.$error" class="invalid-feedback">
                      <span v-if="!$v.form.title.required">This value is required.</span>
                    </div>
                  </div>
                </div>
                </div>
                <div class="row">
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="validationCustom02"> Description</label>
                    <div>
                      <input
                        id="validationCustom02"
                        v-model="form.description"
                        type="text"
                        class="form-control"
                        placeholder="Enter Description"
                        :class="{ 'is-invalid': submitted && $v.form.description.$error }"
                      />
                      <div v-if="submitted && $v.form.description.$error" class="invalid-feedback">
                        <span v-if="!$v.form.description.required">This value is required.</span> 
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <label for="validationCustom02">Call to action Link</label>
                    <div>
                      <input
                        id="validationCustom03"
                        v-model="form.callToActionURL"
                        type="text"
                        class="form-control"
                        placeholder="Enter Call to action Link "
                        :class="{ 'is-invalid': submitted && $v.form.callToActionURL.$error }"
                      />
                      <div v-if="submitted && $v.form.callToActionURL.$error" class="invalid-feedback">
                        <span v-if="!$v.form.callToActionURL.required">This value is required.</span> 
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="spinner" v-if="submitted">
                                <i class="ri-loader-line spin-icon"></i>
                            </div>
              <button v-if="!submitted" class="btn btn-primary" type="submit">Add New Advertisement</button>
            </form>
          </div>
        </div>
      </div>
       <div class="col-xl-7">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    Show&nbsp;
                    <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entries
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    Search:
                    <b-form-input
                      v-model="filter"
                      type="search"
                      placeholder="Search..."
                      class="form-control form-control-sm ml-2"
                    ></b-form-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Table -->
            <div class="table-responsive mb-0">
              <b-table
                
                :items="tableData"
                :fields="fields"
                responsive="sm"
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
              <template #cell(actions)="row">
                <a
                  @click="storeState(row)"
                  href="javascript:void(0);"
                  class="mr-3 text-primary"
                  v-b-tooltip.hover
                  title="Edit"
                >

                  <i v-b-modal.modal-standard class="mdi mdi-pencil font-size-18" ></i>
                 
                </a>
                <a
                  @click="row.toggleDetails"
                  href="javascript:void(0);"
                  class="text-danger"
                  v-b-tooltip.hover
                  title="Disable"
                >
                  <i class="mdi mdi-cancel font-size-18"></i>
                </a>

              </template>
      </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-right">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </Layout>
</template>